import OSS from "ali-oss"
import {bucket} from "@/utils/http.js"

export function client(){
    var client= new OSS({
        region:"oss-cn-chengdu",
        endpoint:"oss-cn-chengdu.aliyuncs.com",//填写Bucket所在地域
        accessKeyId:"LTAI5tMcw8NE6zrUD1kJ1upi",
        accessKeySecret:"jSrijmWOUKFccu8giHxXgvDkGUULgP",
        bucket:bucket,//填写Bucket名称
    })
    return client
}
//生成随机uuid
export const getFileNameUUID=()=>{
    function rx(){
        return (((1+Math.random())*0x10000) | 0).toString(16).substring(1)
    }
    return `${+new Date()}_${rx()}${rx()}`
}
// export {
//     headers
// }